import { CheckCard, Key, ProFormText, } from "@ant-design/pro-components";
import usersApi from "../api";
import transactionApi from "../../transactions/api";
import { UserRead } from "../types";
import { AntPageContainer } from "../../shared/components/AntPageContainer";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import transactionsApi from "../../transactions/api";

export const UserPayment = () => {
    const defaultTipValue = 10;
    const { id } = useParams();
    const [user, setUser] = useState<UserRead | undefined>(undefined);
    const [showCustomValue, setShowCustomValue] = useState<boolean>(false);
    const [tipValue, setTipValue] = useState<number | undefined>(defaultTipValue);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);

    useEffect(() => {
        usersApi.clientRead(id!)
            .then((userData) => {
                setUser(userData);
            })
            .catch((error) => {
            });
    }, [id]);

    return (
        <div className="flex items-center justify-center h-screen bg-cover bg-top mainContainer overflow-hidden">
            <img style={{ filter: "blur(9px)" }} className="h-screen w-screen fixed z-[-1] object-cover scale-[1.1]" src="https://www.shutterstock.com/shutterstock/photos/794601529/display_1500/stock-photo-tip-box-money-bank-and-coins-in-the-vintage-glass-on-wood-counter-in-cafe-794601529.jpg" />
            <style>
                {`
                .mainContainer{
                    background-image: url('');
                }
          .ant-pro-checkcard-header {
            justify-content: space-around;
          }

          .ant-pro-checkcard-title {
            font-size: 30px;
          }
        `}
            </style>
            {showSuccess && <div className="bg-[#28a7458a] w-full p-9 py-16 text-white text-3xl text-center">
                <div>
                    <svg className="w-12 h-12 text-gray-800 dark:text-white mb-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 21">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m6.072 10.072 2 2 6-4m3.586 4.314.9-.9a2 2 0 0 0 0-2.828l-.9-.9a2 2 0 0 1-.586-1.414V5.072a2 2 0 0 0-2-2H13.8a2 2 0 0 1-1.414-.586l-.9-.9a2 2 0 0 0-2.828 0l-.9.9a2 2 0 0 1-1.414.586H5.072a2 2 0 0 0-2 2v1.272a2 2 0 0 1-.586 1.414l-.9.9a2 2 0 0 0 0 2.828l.9.9a2 2 0 0 1 .586 1.414v1.272a2 2 0 0 0 2 2h1.272a2 2 0 0 1 1.414.586l.9.9a2 2 0 0 0 2.828 0l.9-.9a2 2 0 0 1 1.414-.586h1.272a2 2 0 0 0 2-2V13.8a2 2 0 0 1 .586-1.414Z" />
                    </svg>
                </div>
                Thanks for tipping {user?.firstName + " " + user?.lastName}
            </div>
            }
            {!showSuccess && <div className="pt-[300px] md:py-16 px-4 md:px-6 2xl:px-0 flex justify-center items-center 2xl:mx-auto 2xl:container">
                <div className="flex flex-col justify-start items-start w-full space-y-9">
                    <div className="flex justify-start flex-col items-start space-y-2">
                    </div>
                    <div className="flex flex-col xl:flex-row justify-center xl:justify-between space-y-6 xl:space-y-0 xl:space-x-6 w-full">
                        <div className="xl:w-3/5 flex flex-col sm:flex-row xl:flex-col justify-center items-center bg-gray-100  py-7 sm:py-0 pt-36 xl:py-10 px-10 xl:w-full relative">
                            <div className="absolute top-[-100px] left-1/2 transform -translate-x-1/2 h-[200px] w-[200px] bg-gray-200 rounded-full overflow-hidden">
                                <img src="https://img.freepik.com/premium-photo/indian-delivery-man-mock-up_599862-4469.jpg?w=2000" alt="" className="h-full w-full object-cover" />
                            </div>
                            <div className="mb-12 text-3xl"> Tip: {user?.firstName + " " + user?.lastName} </div>
                            <CheckCard.Group className="w-full"
                                onChange={(value) => {
                                    console.log('value', value);
                                    if (value === "-1") {
                                        setShowCustomValue(true);
                                    }
                                    else {
                                        setShowCustomValue(false);
                                        setTipValue(parseFloat(value ? value.toString() : ""));
                                    }
                                }}
                                defaultValue="10"
                            >
                                <CheckCard title={"AED " + defaultTipValue} value={defaultTipValue} className="w-full" />
                                <CheckCard title="AED 20" value="20" className="w-full" />
                                {!showCustomValue && <CheckCard title="Custom" value="-1" className="w-full" />}
                            </CheckCard.Group>
                            {showCustomValue && <input autoFocus type="number" className="w-full p-0 h-[81px] text-center rounded-r-md text-3xl text-black block ml-4 mr-4" onChange={(e) => { setTipValue(parseFloat(e.target.value ? e.target.value : "0")) }} />}

                        </div>
                        <div className="p-8 bg-gray-100 flex flex-col lg:w-full xl:w-3/5">
                            <button className="border border-transparent transition-transform transform hover:scale-105 bg-gray-900 hover:bg-white hover:border-gray-900 text-white hover:text-gray-900 flex flex-row justify-center items-center space-x-2 py-4 rounded w-full">
                                <div>
                                    <svg className="fill-current" width={32} height={32} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.9099 4.27692C9.6499 4.27692 9.1174 4.87817 8.2399 4.87817C7.34021 4.87817 6.65396 4.28129 5.56208 4.28129C4.49333 4.28129 3.35365 4.93379 2.6299 6.04535C1.61365 7.61285 1.78615 10.565 3.43208 13.08C4.02083 13.9804 4.80708 14.99 5.83833 15.001H5.85708C6.75333 15.001 7.01958 14.4141 8.25302 14.4072H8.27177C9.48677 14.4072 9.73052 14.9975 10.623 14.9975H10.6418C11.673 14.9866 12.5015 13.8679 13.0902 12.971C13.514 12.326 13.6715 12.0022 13.9965 11.2725C11.6155 10.3688 11.233 6.99348 13.5877 5.69942C12.869 4.79942 11.859 4.27817 10.9068 4.27817L10.9099 4.27692Z" fill="currentColor" />
                                        <path d="M10.6338 1C9.88379 1.05094 9.00879 1.52844 8.49629 2.15188C8.03129 2.71688 7.64879 3.555 7.79879 4.36781H7.85879C8.65754 4.36781 9.47504 3.88688 9.95254 3.27063C10.4125 2.68406 10.7613 1.85281 10.6338 1V1Z" fill="currentColor" />
                                    </svg>
                                </div>
                                <div>
                                    <p className="text-base leading-4"> AED {tipValue}</p>
                                </div>
                            </button>
                            <div className="flex flex-row justify-center items-center mt-6">
                                <hr className="border w-full" />
                                <p className="flex flex-shrink-0 px-4 text-base leading-4 text-gray-600 ">or pay with card</p>
                                <hr className="border w-full" />
                            </div>
                            <label className="mt-8 text-base leading-4 text-gray-800 ">Card details</label>
                            <div className="mt-2 flex-col">
                                <div className="border-solid border-0 border-b-[1.5px]">
                                    <input className="border-none rounded-tl rounded-tr  p-4 w-[calc(100%-34px)] text-base leading-4 placeholder-gray-600 text-gray-600" type="email" placeholder="0000 1234 6549 15151" />
                                </div>
                                <div className="flex-row flex">
                                    <input className="border-none rounded-bl p-4 w-full text-base leading-4 placeholder-gray-600 text-gray-600" type="email" placeholder="MM/YY" />
                                    <input className="border-none rounded-br p-4 w-full text-base leading-4 placeholder-gray-600 text-gray-600" type="email" placeholder="CVC" />
                                </div>
                            </div>
                            <label className="mt-8 text-base leading-4 text-gray-800 ">Name on card</label>
                            <div className="mt-2 flex-col">
                                <div>
                                    <input className="border-none rounded  p-4 w-[calc(100%-34px)] text-base leading-4 placeholder-gray-600 text-gray-600" type="email" placeholder="Name on card" />
                                </div>
                            </div>

                            <button className=" mt-8 border border-transparent transition-transform transform hover:scale-105 bg-gray-900 hover:bg-white hover:border-gray-900 text-white hover:text-gray-900 flex justify-center items-center py-4 rounded w-full"
                                onClick={() => {
                                    if (tipValue && id) {
                                        transactionsApi.pay({ value: tipValue, currency: "AED", userId: parseFloat(id) }).then(() => { setShowSuccess(true) })
                                    }
                                }}>
                                <div>
                                    <p className="leading-4 text-lg">Pay AED {tipValue}</p>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>}
        </div>

    );
};


