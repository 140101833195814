type AntTableParams = {
  pageSize?: number;
  current?: number;
};

export const getPaginationParams = (antParams: AntTableParams) => {
  return {
    page: antParams.current || 1,
    perPage: antParams.pageSize || 10,
  };
};

export const convertDotNotationToObject = (
  data: Record<string, any>
): Record<string, any> => {
  const result: Record<string, any> = {};

  for (const key in data) {
    const keys = key.split(".");
    let currentObject: Record<string, any> = result;

    for (let i = 0; i < keys.length - 1; i++) {
      const nestedKey = keys[i];
      currentObject[nestedKey] = currentObject[nestedKey] || {};
      currentObject = currentObject[nestedKey];
    }

    currentObject[keys[keys.length - 1]] = data[key];
  }

  return result;
};

export const flattenObject = (
  obj: Record<string, any>,
  parentKey: string = ""
): Record<string, any> => {
  let result: Record<string, any> = {};

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let newKey = parentKey ? `${parentKey}.${key}` : key;

      if (typeof obj[key] === "object" && obj[key] !== null) {
        // Recursively flatten nested objects
        Object.assign(result, flattenObject(obj[key], newKey));
      } else {
        // Assign the value to the new key
        result[newKey] = obj[key];
      }
    }
  }

  return result;
};
export const LANGUAGES = [
  { label: "English", code: "en" },
  { label: "French", code: "fr" },
  { label: "Spanish", code: "es" },
  { label: "Chinese", code: "cn" },
];

export const getLanguageName = (key: string) =>
  LANGUAGES.find((lang) => lang.code === key)?.label || "";

export const isValidUploadSize = (size: number) => size < (Number(process.env.REACT_APP_IMAGE_UPLOAD_SIZE_LIMIT) || 1100000);

export const formatFieldOptions = (field: any, primaryKey: string, language: string = "en", nameKey: string = "name") => {
  try {
    return field?.map((fieldItem: any) => ({
      value: fieldItem[primaryKey],
      label: JSON.parse(fieldItem[nameKey])[language] || JSON.parse(fieldItem[nameKey])?.en || fieldItem[nameKey],
    }));
  } catch {
    return field?.map((fieldItem: any) => ({
      value: fieldItem[primaryKey],
      label: fieldItem[nameKey],
    }));
  }
};

export const getMultilingualDefaultJson = (defaultEn?: string) => {
  return JSON.stringify(LANGUAGES.reduce((obj: any, language) => {
    obj[language.code] = defaultEn && language.code === 'en' ? defaultEn : "";
    return obj;
  }, {}));
}

export const getTranslatedColumnValue = (column: string, language?: string) => {
  try{
    const translated = JSON.parse(column)[language || 'en'];
    return translated || JSON.parse(column)?.en || column;
  } catch {
    return column;
  }
}