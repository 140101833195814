import authApi from './api';
import { LoginRequest } from './types';
import getStore from './store';

export class TokenError extends Error {}

export const login = async (request: LoginRequest) => {
  const response = await authApi.login(request);
  const store = getStore();
  await store.setTokens(response.data!);
  await refetchUser();
  return response;
};

export const refetchUser = async () => {
  const response = await authApi.me();
  const store = getStore();
  await store.setUser(response.data!);
};

export const logout = async () => {
  const store = getStore();
  await store.setTokens(null);
  await store.setUser(null);
};

export const clearAuthState = async () => {
  const store = getStore();
  await store.setTokens(null);
  await store.setUser(null);
};

export const refreshToken = async () => {
  const store = getStore();
  let tokens = store.getTokens();

  if (!tokens || !tokens.refreshToken || !tokens.accessTokenExpiryTs) {
    throw new TokenError('token missing');
  }

  const now = new Date();

  // access token is still valid
  const accessTokenExpiryDate = new Date(tokens.accessTokenExpiryTs * 1000);
  const accessTokenExpired = accessTokenExpiryDate.getTime() <= now.getTime();
  if (!accessTokenExpired) {
    return;
  }

  try {
    // get new access token and save it
    const refreshToken = tokens.refreshToken;
    const response = await authApi.refreshToken({ refreshToken });
    tokens = store.getTokens();
    await store.setTokens({
      refreshToken: response.data!.refreshToken || tokens?.refreshToken || '',
      accessToken: response.data!.accessToken,
      accessTokenExpiryTs: response.data!.accessTokenExpiryTs,
    });
  } catch {
    logout();
  }
};
