import axios from '../axios-client/instance';
import { ApiResponse } from '../shared/types';
import { convertDotNotationToObject } from '../shared/utils/common';
import {
  CreateUser,
  ListUserRequest,
  ListUserResponse,
  UserRead,
} from './types';

const usersApi = {
  async index(request: ListUserRequest) {
    const { data } = await axios.get<ListUserResponse>('/admin/users', {
      params: request,
    });
    return data;
  },

  async create(request: CreateUser) {
    const { data } = await axios.post<ApiResponse<UserRead>>(
      '/admin/users',
      convertDotNotationToObject(request)
    );
    return data;
  },

  async update(id: string, request: CreateUser) {
    const { data } = await axios.put<ApiResponse<void>>(
      `/admin/users/${id}`,
      convertDotNotationToObject(request)
    );
    return data;
  },

  async read(id: string) {
    const { data } = await axios.get<ApiResponse<UserRead>>(
      `/admin/users/${id}`
    );
    return data;
  },
  async clientRead(id: string) {
    const {
      data: { data },
    } = await axios.get<ApiResponse<UserRead>>(`/users/${id}`);
    return data;
  },
};

export default usersApi;
