import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import enUS from "antd/locale/en_US";
import { ConfigProvider, theme } from "antd";
import "./i18n";
import { LoadingSpinner } from "./shared/components/loading-spinner/LoadingSpinner";
import { AlertProvider } from "./shared/context/AlertContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.Suspense fallback={LoadingSpinner}>
    <ConfigProvider locale={enUS}>
      <ConfigProvider theme={{ algorithm: theme.defaultAlgorithm }}>
        <AlertProvider>
          <App />
        </AlertProvider>
      </ConfigProvider>
    </ConfigProvider>
  </React.Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
