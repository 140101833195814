import {
  ProFormDatePicker,
  ProFormGroup,
  ProFormSelect,
  ProFormText,
} from "@ant-design/pro-components";
import { Gender } from "../../enums";

export const gender: Record<Gender, string> = {
  [Gender.Male]: "Male",
  [Gender.Female]: "Female",
};

export const ProfileFields = () => {
  return (
    <ProFormGroup title="Profile">
      <ProFormDatePicker
        colProps={{ xs: 12, md: 4 }}
        name="profile.birthDate"
        label="Birthdate"
      />

      <ProFormSelect
        colProps={{ xs: 12, md: 4 }}
        name="profile.gender"
        label="Gender"
        valueEnum={gender}
      />

      <ProFormText
        colProps={{ xs: 12, lg: 4 }}
        name="profile.height"
        label="Height"
      />

      <ProFormText
        colProps={{ xs: 12, lg: 4 }}
        name="profile.weight"
        label="Weight"
      />
    </ProFormGroup>
  );
};
