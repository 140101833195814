import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import './json-editor.css';
import { ProFormText } from '@ant-design/pro-components';
import { Form, FormInstance } from "antd";
import { useEffect, useState } from 'react';
import { getMultilingualDefaultJson } from '../../utils/common';

export default function JsonEditor(props: any) {
    const form: FormInstance = props.form;
    const values = Form.useWatch([], form);
    let [editorValue, setEditorValue] = useState();

    const handleEditorValueChange = (e: any) => {
        form.setFieldValue(props.name, JSON.stringify(e));
    };

    useEffect(() => {
        if (values && values[props.name] && !editorValue) {
            try {
                setEditorValue(JSON.parse(values[props.name]));
            } catch {
                setEditorValue(JSON.parse(getMultilingualDefaultJson(values[props.name])))
            }
        }
        if (values && !values[props.name]) {
            form.setFieldValue(props.name, getMultilingualDefaultJson());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    return (
        editorValue !== undefined ? (<div className="json-editor-wrapper">
            <Editor value={editorValue} onChange={handleEditorValueChange} {...props} />
            <ProFormText
                colProps={{ span: "auto" }}
                name={props.name}
                rules={[{ required: true, message: "required", }]}
                hidden
            />
        </div>) : <></>
    );

}