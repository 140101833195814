import { ProCard, ProForm } from "@ant-design/pro-components";
import { Form } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { AntPageContainer } from "../../shared/components/AntPageContainer";
import transactionsApi from "../api";
import { TransactionFormFields } from "../components/field-groups/TransactionFormFields";
import {
    toApiRequest,
    toFormState,
    UpdateTransactionFormState,
} from "../form-state/update-transaction-form-state";
import { useEffect, useState } from "react";
import useAlert from "../../shared/hooks/useAlert";
import { validateJsonInput } from "../../shared/utils/validators";
import { AxiosError } from "axios";
import { ApiResponse } from "../../shared/types";
import { Transaction } from "../types";


export const UpdateTransaction = () => {
    const { id } = useParams();
    const [form] = Form.useForm();
    const values = Form.useWatch([], form);
    const { setAlert } = useAlert();
    const [transaction, setTransaction] = useState<Transaction | undefined>(undefined);
    const [validationError, setValidationError] = useState("")
    const [canSubmit, setCanSubmit] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setValidationError('');
        console.log(values);
        form.validateFields({ validateOnly: true }).then(
            () => {
                if (values?.value < values?.paidValue || values?.paidValue < 0) {
                    setValidationError("Paid value must be positive and less or equal the actual amount .")
                    setCanSubmit(false);
                }
                else {
                    setCanSubmit(true);
                }
                //  setCanSubmit(validateJsonInput([values?.name]));
            },
            () => {
                setCanSubmit(false);
            }
        ).catch((errorInfo) => {
            // Validation failed
            console.log('Validation error:', errorInfo);
            // setCanSubmit(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    useEffect(() => {
        // Fetch the transaction object when the component mounts
        transactionsApi.findOne(id!)
            .then((transactionData) => {
                setTransaction(transactionData);
                // console.log(transactionData);
            })
            .catch((error) => {
            });
    }, [id]);

    useEffect(() => {
        // Watch for changes in the transaction state and update the form
        if (transaction) {
            form.setFieldsValue(toFormState(transaction));
        }
    }, [transaction, form]);

    return (
        <AntPageContainer>
            <ProCard>
                <ProForm<UpdateTransactionFormState>
                    grid
                    form={form}
                    submitter={{
                        submitButtonProps: { disabled: !canSubmit },
                    }}
                    onFinish={async (data) => {
                        const request = toApiRequest(data);
                        try {
                            const { message } = await transactionsApi.update(
                                id!,
                                request
                            );
                            setAlert(message!, "success");
                            navigate(-1);
                        } catch (err) {
                            const defaultErrMsg = "Something went wrong, please try again later.";

                            if (err instanceof AxiosError) {
                                const response = err.response?.data as ApiResponse<any>

                                if (response) {
                                    const { message } = response;
                                    setAlert(message ?? defaultErrMsg, "error");
                                    return;
                                }
                            }

                            setAlert(defaultErrMsg, "error");
                        }
                    }}
                    // request={() => transactionsApi.findOne(id!).then(toFormState)}
                    request={async () => { return toFormState(transaction); }}
                >
                    <TransactionFormFields object={transaction} form={form} />
                    {validationError && <div className="text-[#ff0000] px-1 pb-4">{validationError}</div>}
                </ProForm>
            </ProCard>
        </AntPageContainer>
    );
};
