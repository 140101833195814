import { Key, ProTable, ProTableProps } from '@ant-design/pro-components';
import { getPaginationParams } from '../../shared/utils/common';
import usersApi from '../api';
import { ListUserRequest, UserRead } from '../types';
import { AntPageContainer } from '../../shared/components/AntPageContainer';
import { Button } from 'antd';
import {
  EditOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import authApi from '../../auth/api';
import { PaginationResponse } from '../../shared/types';
import { TableSelectedItemsActionModal } from '../../shared/components/table-selected-items-modal/TableSelectedItemsActionModal';

const fetchUsers: ProTableProps<UserRead, ListUserRequest>['request'] = async (
  params
) => {
  const { id, email, username, firstName, lastName, ...paginationParams } =
    params;

  const request: ListUserRequest = {
    id,
    email,
    username,
    firstName,
    lastName,
    sortOrder: 'ascend',
    ...getPaginationParams(paginationParams),
  };

  const response = await usersApi.index(request);
  const { items: data, total } = response.data as PaginationResponse<
    UserRead[]
  >;

  return { data, total };
};

export const ListUser = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const onChange = (selectedKeys: Key[]) => {
    setSelectedRowKeys(selectedKeys);
  };

  return (
    <AntPageContainer>
      <TableSelectedItemsActionModal
        requestFunc={authApi.revokeToken}
        itemIds={selectedRowKeys as number[]}
        isOpen={isModalOpen}
        title="Delete confirmation"
        okType="danger"
        onCancel={() => setIsModalOpen(false)}
        onSuccessCb={() => {
          setIsModalOpen(false);
          setSelectedRowKeys([]);
        }}
        description={`Are you sure you want to revoke the selected token${
          selectedRowKeys.length > 1 ? 's' : ''
        }`}
      />

      <ProTable<UserRead, ListUserRequest>
        request={fetchUsers}
        form={{ syncToUrl: true }}
        toolBarRender={(_, { selectedRowKeys }) => [
          <Link to="create">
            <Button icon={<PlusOutlined />} type="primary">
              Create
            </Button>
          </Link>,
          <Button
            icon={<MinusCircleOutlined />}
            type="primary"
            danger
            disabled={(selectedRowKeys || []).length < 1}
            onClick={() => setIsModalOpen(true)}
          >
            Revoke Token
          </Button>,
        ]}
        rowSelection={{
          selectedRowKeys,
          onChange,
          preserveSelectedRowKeys: true,
        }}
        rowKey="id"
        columns={[
          {
            key: 'id',
            dataIndex: 'id',
            title: 'ID',
            copyable: true,
          },
          {
            key: 'email',
            dataIndex: 'email',
            title: 'Email',
          },
          {
            key: 'username',
            dataIndex: 'username',
            title: 'Username',
          },
          {
            key: 'firstName',
            dataIndex: 'firstName',
            title: 'First name',
          },
          {
            key: 'lastName',
            dataIndex: 'lastName',
            title: 'Last name',
          },
          {
            key: 'actions',
            valueType: 'option',
            title: 'Actions',
            render: (_, row) => [
              <Link key={row.id} to={`edit/${row.id}`}>
                <EditOutlined />
              </Link>,
            ],
          },
        ]}
      />
    </AntPageContainer>
  );
};
