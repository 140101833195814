import {
  ProForm,
  ProFormDatePicker,
  ProFormDigit,
  ProFormMoney,
  ProFormSegmented,
  ProFormSelect,
  ProFormSwitch,
  ProFormText,
  ProFormTextArea,
} from "@ant-design/pro-components";
import { ColProps } from "antd";
import { Rule } from "antd/lib/form";
import JsonEditor from "../json-editor/json-editor";

export type FormInputFieldType = {
  options: {
    name: string;
    type: 'text' | 'json' | 'select' | 'textArea' | 'number' | 'segmented' | 'datePicker' | 'switch' | 'money';
    label: string;
    hidden?: boolean;
    rules?: Rule[]
    colProps?: ColProps;
    rest?: any
  };
  form?: any;
}

export const FormInputField = ({ options, form }: FormInputFieldType) => {

  const props = {
   ...options,
    ...options.rest
  }

  switch (options?.type) {
    case "text":
      return <ProFormText {...props} />
    case "textArea":
      return <ProFormTextArea {...props} />
    case "number":
      return <ProFormDigit {...props} />
    case "switch":
      return <ProFormSwitch {...props} />
    case "datePicker":
      return <ProFormDatePicker {...props} />
    case "select":
      return <ProFormSelect {...props} />
    case 'segmented':
      return <ProFormSegmented {...props} />
    case 'money':
      return <ProFormMoney {...props} />
    case 'json':
      return <div className="mb-6 w-full">
        <ProForm.Item
          label={options.label}
          name={options.name}
          style={{ color: "red", marginBottom: 0 }}
          rules={options.rules}
          {...options.rest}
        >
          <div>
            <JsonEditor form={form} name={options.name} navigationBar={false} search={false} statusBar={false} />
          </div>
        </ProForm.Item>
        {options?.rules?.some((rule: any) => rule.required) && <div className="p-1 text-[#909090]">Please fill out the English (en) key to proceed. The other languages are optional.</div>}
      </div>
    default: return <></>
  }
};
