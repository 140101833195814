import {
  CreateTransaction,
  ListTransactionRequest,
  ListTransactionResponse,
  Transaction,
  UpdateTransaction,
} from "./types";
import { ApiResponse } from "../shared/types";
import axios from "../axios-client/instance";

const transactionsApi = {
  // async create(request: CreateTransaction) {
  //   const { data } = await axios.post<ApiResponse<Transaction>>(
  //     "/admin/transactions",
  //     request
  //   );
  //   return data;
  // },

  async update(id: string, request: UpdateTransaction) {
    const { data } = await axios.put<ApiResponse<Transaction>>(
      `/admin/transactions/${id}`,
      request
    );
    return data;
  },

  async findOne(id: string) {
    const {
      data: { data },
    } = await axios.get<ApiResponse<Transaction>>(`/admin/transactions/${id}`);
    return data;
  },

  // async delete(id: string) {
  //   const { data } = await axios.delete<ApiResponse<void>>(
  //     `/admin/transactions/${id}`
  //   );
  //   return data;
  // },

  // async deleteMany(ids: number[]) {
  //   const { data } = await axios.delete<ApiResponse<void>>(
  //     `/admin/transactions?ids=${ids.join(",")}`
  //   );
  //   return data;
  // },

  async index(request: ListTransactionRequest) {
    const { data } = await axios.get<ListTransactionResponse>(
      "/admin/transactions",
      {
        params: request,
      }
    );
    return data;
  },

  async pay(request: CreateTransaction) {
    const { data } = await axios.post<ApiResponse<Transaction>>(
      `/transactions`,
      request
    );
    return data;
  },
};



export default transactionsApi;
