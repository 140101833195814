import axios from '../axios-client/instance';
import refreshAxios from '../axios-client/refresh-instance';
import { ApiResponse } from '../shared/types';
import {
  LoginRequest,
  TokenResponse,
  AuthUser,
  RefreshTokenRequest,
  RefreshTokenResponse,
} from './types';

const authApi = {
  async login(request: LoginRequest) {
    console.log(request);
    const { data } = await axios.post<ApiResponse<TokenResponse>>(
      '/auth/login',
      request,
      {
        requiresAuth: false,
      }
    );
    console.log(data);
    return data;
  },

  async me() {
    const { data } = await axios.get<ApiResponse<AuthUser>>('/auth/me', {
      requiresAuth: true,
    });
    return data;
  },

  async refreshToken(request: RefreshTokenRequest) {
    const { data } = await refreshAxios.post<ApiResponse<RefreshTokenResponse>>(
      '/auth/refresh-token',
      request,
      { requiresAuth: false }
    );
    return data;
  },

  async revokeToken(ids: number[]) {
    const { data } = await axios.post<ApiResponse<void>>(
      '/auth/revoke-token',
      { ids },
      { requiresAuth: false }
    );
    return data;
  },
};

export default authApi;
