import { EyeOutlined } from '@ant-design/icons';
import {
  ActionType,
  ProTable,
  ProTableProps,
} from '@ant-design/pro-components';
import { Link } from 'react-router-dom';
import { AntPageContainer } from '../../shared/components/AntPageContainer';
import { getPaginationParams } from '../../shared/utils/common';
import transactionsApi from '../api';
import { ListTransactionRequest, Transaction } from '../types';
import { useRef } from 'react';
import useAlert from '../../shared/hooks/useAlert';
import { PaginationResponse } from '../../shared/types';
import { ListTransactionSortFields } from '../enums';

export const ListTransaction = () => {
  const { setAlert } = useAlert();

  const ref = useRef<ActionType>();

  // const onChange = (selectedKeys: Key[]) => {
  //   setSelectedRowKeys(selectedKeys);
  // };

  const fetchTransaction: ProTableProps<
    Transaction,
    ListTransactionRequest
  >['request'] = async (params, sort, _) => {
    const request: ListTransactionRequest = {
      id: params.id,
      name: params.name,
      username: params.username,
      deleted: params.deleted,
      sortField: ListTransactionSortFields.CreatedAt,
      sortOrder: 'ascend',
      ...getPaginationParams(params),
    };

    if (sort?.createdAt) {
      params.sortField = ListTransactionSortFields.CreatedAt;
      params.sortOrder = sort.createdAt;
    }

    if (sort?.updatedAt) {
      params.sortField = ListTransactionSortFields.UpdatedAt;
      params.sortOrder = sort.updatedAt;
    }

    const response = await transactionsApi.index(request);
    const { items: data, total } = response.data as PaginationResponse<
      Transaction[]
    >;

    return { data, total };
  };

  return (
    <AntPageContainer>
      {/* <TableSelectedItemsActionModal
        requestFunc={transactionsApi.deleteMany}
        itemIds={selectedRowKeys as number[]}
        isOpen={isModalOpen}
        title="Delete confirmation"
        okType="danger"
        onCancel={() => setIsModalOpen(false)}
        onSuccessCb={() => {
          setIsModalOpen(false);
          setSelectedRowKeys([]);
          ref.current?.reload();
        }}
        description={`Are you sure you want to delete the selected item${selectedRowKeys.length > 1 ? "s" : ""}`}
      /> */}
      <ProTable<Transaction, ListTransactionRequest>
        request={fetchTransaction}
        actionRef={ref}
        form={{ syncToUrl: true }}
        search={{ searchText: 'Search' }}
        onRequestError={(e) => {
          // log error to service like sentry here
          setAlert('Oops! Something went wrong retrieving the data!', 'error');
        }}
        // rowSelection={{
        //   selectedRowKeys,
        //   onChange,
        //   preserveSelectedRowKeys: true,
        // }}
        toolBarRender={(action, rows) => [
          // <Link to="create">
          //   <Button icon={<PlusOutlined />} type="primary">
          //     Create
          //   </Button>
          // </Link>,
          // <Button
          //   icon={<MinusCircleOutlined />}
          //   type="primary"
          //   danger
          //   disabled={(selectedRowKeys || []).length < 1}
          //   onClick={() => setIsModalOpen(true)}
          // >
          //   Delete
          // </Button>,
        ]}
        rowKey="transactionId"
        columns={[
          {
            key: 'transactionId',
            dataIndex: 'transactionId',
            title: 'ID',
            copyable: true,
          },
          {
            key: 'username',
            dataIndex: 'username',
            title: 'Username',
            // copyable: true,
            render: (_, entity) => {
              return entity.user.username;
            },
          },
          {
            key: 'value',
            title: 'Value',
            dataIndex: 'value',
            render: (_, entity) => {
              return entity.value;
            },
          },
          {
            key: 'currency',
            title: 'Currency',
            dataIndex: 'currency',
            render: (_, entity) => {
              return entity.currency;
            },
          },
          {
            key: 'status',
            title: 'Status',
            hideInSearch: true,
            render: (_, entity) => {
              return entity?.status;
            },
          },
          // {
          //   key: "log",
          //   title: "log",
          //   ellipsis: true,
          //   render: (_, entity) => {
          //     return entity?.log;
          //   },
          //   hideInSearch: true,
          // },
          {
            key: 'actions',
            valueType: 'option',
            title: 'Actions',
            render: (dom, row, index) =>
              // row.deletedAt
              //   ? // maybe add restore functionality?
              //   []
              //   :
              [
                <Link key={row.transactionId} to={`edit/${row.transactionId}`}>
                  <EyeOutlined />
                </Link>,
              ],
          },
          // {
          //   key: "deleted",
          //   title: "Deleted",
          //   dataIndex: "deleted",
          //   valueType: "switch",
          //   hideInTable: true,
          // },
        ]}
      />
    </AntPageContainer>
  );
};
