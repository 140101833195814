import { Button, Popconfirm } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { ProCard, ProForm } from "@ant-design/pro-components";
import { CreateUser } from "../types";
import { AntPageContainer } from "../../shared/components/AntPageContainer";
import { GeneralFields } from "./field-groups/GeneralFields";
import { ProfileFields } from "./field-groups/ProfileFields";
import usersApi from "../api";
import { AdministrativeFields } from "./field-groups/AdministrativeFields";
import { flattenObject } from "../../shared/utils/common";
import authApi from "../../auth/api";
import useAlert from "../../shared/hooks/useAlert";
import { ApiResponse } from "../../shared/types";
import { AxiosError } from "axios";

export const UserForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { setAlert } = useAlert();

  const onConfirm = async () => {
    if (!id) return;
    const { message, success } = await authApi.revokeToken([+id]);
    setAlert(message!, success ? "success" : "error");
  };

  return (
    <AntPageContainer>
      <ProCard>
        <ProForm<CreateUser>
          grid
          {...(id && {
            request: async () => {
              const response = await usersApi.read(id);
              return flattenObject(response.data!) as CreateUser;
            },
          })}
          onFinish={async (data) => {
            try {
              if (!id) {
                const response = await usersApi.create(data);
                setAlert(response.message!, "success");
              } else {
                const response = await usersApi.update(id, data);
                setAlert(response.message!, "success");
              }

              navigate(-1);
            } catch (err) {
              const defaultErrMsg = "Something went wrong, please try again later.";

              if (err instanceof AxiosError) {
                const response = err.response?.data as ApiResponse<any>

                if (response) {
                  const { message } = response;
                  setAlert(message ?? defaultErrMsg, "error");
                  return;
                }
              }

              setAlert(defaultErrMsg, "error");
            }
          }}
        >
          {id && (
            <Popconfirm
              title="Revoke the token"
              description="Are you sure to revoke the token of this user?"
              onConfirm={onConfirm}
              okText="Yes"
              cancelText="No"
              placement="bottomLeft"
            >
              <Button
                type="primary"
                danger
                style={{ marginLeft: "auto", marginBottom: 12 }}
              >
                Revoke Token
              </Button>
            </Popconfirm>
          )}
          <GeneralFields />
          <ProfileFields />
          <AdministrativeFields />
        </ProForm>
      </ProCard>
    </AntPageContainer>
  );
};
