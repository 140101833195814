import { createBrowserRouter } from "react-router-dom";
import { Guest } from "./auth/components/Guest";
import { Protected } from "./auth/components/Protected";
import { LoginPage } from "./auth/pages/LoginPage";
import { AdminLayout } from "./shared/layouts/AdminLayout";
import { ListUser } from "./users/pages/ListUser";
import { UserForm } from "./users/components/UserForm";
import { ListTransaction } from "./transactions/pages/ListTransaction";
import { UpdateTransaction } from "./transactions/pages/UpdateTransaction";
import { UserPayment } from "./users/pages/UserPayment";
import { DashboardPage } from "./dashboard/pages/DashboardPage";
import { ComingSoon } from "./ComingSoon";
import ListWithdrawal from "./withdrawals/pages/ListWithdrawal";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Guest>
        <ComingSoon />
      </Guest>
    ),
  },
  {
    path: "/user/:id",
    element: (
      <Guest>
        <UserPayment />
      </Guest>
    ),
  },
  {
    path: '/admin/login',
    element: (
      <Guest>
        <LoginPage />
      </Guest>
    ),
  },
  {
    path: '/admin',
    element: (
      <Protected>
        <AdminLayout />
      </Protected>
    ),
    children: [
      {
        path: 'dashboard',
        element: <DashboardPage />,
      },
      {
        path: 'transactions',
        element: <ListTransaction />,
      },
      {
        path: 'transactions/edit/:id',
        element: <UpdateTransaction />,
      },
      {
        path: 'users',
        element: <ListUser />,
      },
      {
        path: 'withdrawals',
        element: <ListWithdrawal />,
      },
      {
        path: 'users/create',
        element: <UserForm />,
      },
      {
        path: 'users/edit/:id',
        element: <UserForm />,
      },
    ],
  },
]);
