import React, { useRef, useState } from 'react';
import { AntPageContainer } from '../../shared/components/AntPageContainer';
import { TableSelectedItemsActionModal } from '../../shared/components/table-selected-items-modal/TableSelectedItemsActionModal';
import {
  ProTable,
  Key,
  ProTableProps,
  ActionType,
} from '@ant-design/pro-components';
import { Link } from 'react-router-dom';
import { CheckCircleOutlined } from '@ant-design/icons';
import authApi from '../../auth/api';
import useAlert from '../../shared/hooks/useAlert';
import { ListWithdrawalRequest, Withdrawal } from '../types';
import { getPaginationParams } from '../../shared/utils/common';
import { PaginationResponse } from '../../shared/types';
import { ListWithdrawalSortFields } from '../enums';
import withdrawalApi from '../api';

const ListWithdrawal = () => {
  const ref = useRef<ActionType>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const { setAlert } = useAlert();

  const fetchWithdrawals: ProTableProps<
    Withdrawal,
    ListWithdrawalRequest
  >['request'] = async (params, sort, _) => {
    const request: ListWithdrawalRequest = {
      id: params.id,
      name: params.name,
      username: params.username,
      deleted: params.deleted,
      sortField: ListWithdrawalSortFields.CreatedAt,
      sortOrder: 'ascend',
      ...getPaginationParams(params),
    };

    if (sort?.createdAt) {
      params.sortField = ListWithdrawalSortFields.CreatedAt;
      params.sortOrder = sort.createdAt;
    }

    if (sort?.updatedAt) {
      params.sortField = ListWithdrawalSortFields.UpdatedAt;
      params.sortOrder = sort.updatedAt;
    }

    const response = await withdrawalApi.index(request);
    const { items: data, total } = response.data as PaginationResponse<
      Withdrawal[]
    >;

    return { data, total };
  };

  const handleApproveWithdrawal = async (id: string, e: any) => {
    e.preventDefault();
    try {
      const approvedWithdrawal = await withdrawalApi.approveWithdrawal(id);
      console.log(approvedWithdrawal);
      if (approvedWithdrawal.status === 200)
        setAlert(approvedWithdrawal.data.message, 'success');
      // const newData = await index();
      // setData(newData.data);
    } catch (error) {
      console.error('Error approving withdrawal:', error);
    }
  };

  return (
    <AntPageContainer>
      <TableSelectedItemsActionModal
        requestFunc={authApi.revokeToken}
        itemIds={selectedRowKeys as number[]}
        isOpen={isModalOpen}
        title="Delete confirmation"
        okType="danger"
        onCancel={() => setIsModalOpen(false)}
        onSuccessCb={() => {
          setIsModalOpen(false);
          setSelectedRowKeys([]);
        }}
        description={`Are you sure you want to revoke the selected token${
          selectedRowKeys.length > 1 ? 's' : ''
        }`}
      />

      <ProTable
        request={fetchWithdrawals}
        actionRef={ref}
        form={{ syncToUrl: true }}
        search={{ searchText: 'Search' }}
        onRequestError={(e) => {
          setAlert('Oops! Something went wrong retrieving the data!', 'error');
        }}
        toolBarRender={(action, rows) => []}
        rowKey="id"
        columns={[
          {
            key: 'id',
            dataIndex: 'id',
            title: 'ID',
            copyable: true,
          },
          {
            key: 'username',
            dataIndex: 'username',
            title: 'Username',
            render: (_, entity) => {
              return entity.user.username;
            },
          },
          {
            key: 'value',
            dataIndex: 'value',
            title: 'Value',
          },
          {
            key: 'currency',
            dataIndex: 'currency',
            title: 'Currency',
          },
          {
            key: 'status',
            dataIndex: 'status',
            title: 'Status',
          },
          {
            key: 'actions',
            valueType: 'option',
            title: 'Actions',
            render: (_, row) => [
              <Link key={row.id} to={`edit/${row.id}`}>
                {row.status !== 'approved' && (
                  <CheckCircleOutlined
                    onClick={(e) => handleApproveWithdrawal(row.id, e)}
                  />
                )}
              </Link>,
            ],
          },
        ]}
      />
    </AntPageContainer>
  );
};

export default ListWithdrawal;
