import { Transaction, UpdateTransaction } from "../types";
import { formatPrice } from "../utils";

/**
 * Data representation used by update transaction form.
 */
export interface UpdateTransactionFormState extends UpdateTransaction { }

/**
 * Convert transaction to formState
 */
export const toFormState = (
  transaction: Transaction | undefined
): UpdateTransactionFormState => {
  const { ...restData } = transaction;
  const request: UpdateTransactionFormState = {
    ...restData,
    valueDisplay: formatPrice(restData.value, restData.currency),
  };

  return request;
};

/**
 * Convert formState to api request
 */
export const toApiRequest = (
  formState: UpdateTransactionFormState
): UpdateTransaction => {
  const { ...restData } = formState;
  const request: UpdateTransaction = restData;
  return request;
};
