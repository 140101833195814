import { useTranslation } from "react-i18next";
import { AntPageContainer } from "../../shared/components/AntPageContainer";
import { PieChart, Pie, BarChart, Bar, LineChart, Line, AreaChart, Area, RadarChart, Radar, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import { Layout, List, Avatar, Statistic, Progress } from 'antd';

const { Header, Content } = Layout;

const samplePieChartData = [
  { name: 'AED 10', value: 400 },
  { name: 'AED 20', value: 300 },
  { name: 'Other', value: 200 },
  // { name: 'Category D', value: 100 },
];
// Define colors for each segment of the pie chart
const colors = ['#3CB9FC', '#B537F2', '#8A2BE2', '#120052'];

const sampleBarChartData = [
  { name: 'Jan', uv: 400, pv: 2400, amt: 2400 },
  { name: 'Feb', uv: 300, pv: 1398, amt: 2210 },
  { name: 'Mar', uv: 200, pv: 9800, amt: 2290 },
  { name: 'Apr', uv: 278, pv: 3908, amt: 2000 },
  { name: 'May', uv: 189, pv: 4800, amt: 2181 },
  { name: 'Jun', uv: 239, pv: 3800, amt: 2500 },
  { name: 'Jul', uv: 349, pv: 4300, amt: 2100 },
];

const sampleLineChartData = [
  { name: 'Jan', uv: 400, pv: 2400, tv: 3400, amt: 2400 },
  { name: 'Feb', uv: 300, pv: 1398, tv: 2398, amt: 2210 },
  { name: 'Mar', uv: 200, pv: 9800, tv: 4800, amt: 2290 },
  { name: 'Apr', uv: 278, pv: 3908, tv: 1908, amt: 2000 },
  { name: 'May', uv: 189, pv: 4800, tv: 7800, amt: 2181 },
  { name: 'Jun', uv: 239, pv: 3800, tv: 1800, amt: 2500 },
  { name: 'Jul', uv: 349, pv: 4300, tv: 1300, amt: 2100 },
];

const sampleAreaChartData = [
  { name: 'Jan', uv: 400, pv: 2400, amt: 2400 },
  { name: 'Feb', uv: 300, pv: 1398, amt: 2210 },
  { name: 'Mar', uv: 200, pv: 9800, amt: 2290 },
  { name: 'Apr', uv: 278, pv: 3908, amt: 2000 },
  { name: 'May', uv: 189, pv: 4800, amt: 2181 },
  { name: 'Jun', uv: 239, pv: 3800, amt: 2500 },
  { name: 'Jul', uv: 349, pv: 4300, amt: 2100 },
];

const sampleRadarChartData = [
  { subject: 'Math', A: 120, B: 110, fullMark: 150 },
  { subject: 'Chinese', A: 98, B: 130, fullMark: 150 },
  { subject: 'English', A: 86, B: 130, fullMark: 150 },
  { subject: 'Geography', A: 99, B: 100, fullMark: 150 },
  { subject: 'Physics', A: 85, B: 90, fullMark: 150 },
  { subject: 'History', A: 65, B: 85, fullMark: 150 },
];

const sampleScatterChartData = [
  { x: 100, y: 200, z: 200 },
  { x: 120, y: 100, z: 260 },
  { x: 170, y: 300, z: 400 },
  { x: 140, y: 250, z: 280 },
  { x: 150, y: 400, z: 500 },
  { x: 110, y: 280, z: 200 },
];

// Sample data for the top 5 users
const topUsersData = [
  { id: 1, name: 'John Doe', score: 950, avatar: 'https://via.placeholder.com/150' },
  { id: 2, name: 'Jane Smith', score: 920, avatar: 'https://via.placeholder.com/150' },
  { id: 3, name: 'Alice Johnson', score: 900, avatar: 'https://via.placeholder.com/150' },
  { id: 4, name: 'Bob Brown', score: 890, avatar: 'https://via.placeholder.com/150' },
  { id: 5, name: 'Emily Davis', score: 880, avatar: 'https://via.placeholder.com/150' },
];


export const DashboardPage = () => {
  const { t } = useTranslation();
  // Sample data for the statistics
  const totalUsers = 1000;
  const usersJoinedLastMonth = 150;
  const totalScore = 50000;
  const lastMonthScore = 2000;

  return (
    <AntPageContainer title={t("dashboard")}>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <div className="bg-white p-4">
          <h2 className="text-lg font-semibold mb-4 text-[#B537F2]">User Statistics</h2>
          <div className="flex mt-10 justify-around items-center">
            <div>
              <Statistic title="Total Users" value={totalUsers} />
            </div>
            <div className="flex justify-center items-center">
              <Progress type="circle" percent={(usersJoinedLastMonth / totalUsers) * 100} width={150} strokeColor={{ from: '#B537F2', to: '#B537F2' }} strokeWidth={8} />
            </div>
            <div>
              <Statistic title="Users Joined Last Month" value={usersJoinedLastMonth} />
            </div>
          </div>
        </div>
        <div className="bg-white p-4">
          <h2 className="text-lg font-semibold mb-4 text-[#B537F2]">Tips Statistics</h2>
          <div className="flex mt-10 justify-around items-center">
            <div>
              <Statistic title="Total Tips" value={totalScore} />
            </div>
            <div className="flex justify-center items-center">
              <Progress type="circle" percent={(lastMonthScore / totalScore) * 100} width={150} strokeColor={{ from: '#B537F2', to: '#B537F2' }} strokeWidth={8} />
            </div>
            <div>
              <Statistic title="Tips Last Month" value={lastMonthScore} />
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="bg-white p-4 col-span-full">
          <h2 className="text-lg font-semibold mb-4 text-[#B537F2]">Tips Distribution</h2>
          <ResponsiveContainer width="100%" height="85%">
            <LineChart width={400} height={300} data={sampleLineChartData}>
              <XAxis dataKey="name" />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Legend />
              <Line name="AED 10" type="monotone" dataKey="pv" stroke="#B537F2" strokeWidth={2} animationDuration={1500} isAnimationActive={true} />
              <Line name="AED 20" type="monotone" dataKey="tv" stroke="#3CB9FC" strokeWidth={2} animationDuration={1500} isAnimationActive={true} />
              <Line name="Other" type="monotone" dataKey="uv" stroke="#8A2BE2" strokeWidth={2} animationDuration={1500} isAnimationActive={true} />

            </LineChart>
          </ResponsiveContainer>
        </div>

        <div className="bg-white p-4 col-span-3">
          <h2 className="text-lg font-semibold mb-4 text-[#B537F2]">Tips vs Payouts</h2>
          <ResponsiveContainer width="100%" height="80%">
            <BarChart width={400} height={300} data={sampleBarChartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="pv" fill="#8A2BE2" name="Tips" />
              <Bar dataKey="uv" fill="#120052" name="Payouts" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="bg-white p-4">
          <h2 className="text-lg font-semibold mb-4 text-[#B537F2]">Top Tipped</h2>
          <List
            itemLayout="horizontal"
            dataSource={topUsersData}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar src={item.avatar} />}
                  title={item.name}
                  description={`Tips: AED ${item.score}`}
                />
              </List.Item>
            )}
          />
        </div>

        <div className="bg-white p-4  col-span-2">
          <h2 className="text-lg font-semibold mb-4 text-[#B537F2]">Area Chart</h2>
          <ResponsiveContainer width="100%" height="80%">
            <AreaChart width={400} height={300} data={sampleAreaChartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Area type="monotone" dataKey="uv" stackId="1" stroke="#3CB9FC" fill="#3CB9FC" />
              <Area type="monotone" dataKey="pv" stackId="1" stroke="#B537F2" fill="#B537F2" />
            </AreaChart>
          </ResponsiveContainer>
        </div>

        <div className="bg-white p-4 col-span-2">
          <h2 className="text-lg font-semibold mb-4 text-[#B537F2]">Tip Distribution</h2>
          <div className="flex items-center justify-center" >
            <ul className="">
              {samplePieChartData.map((entry, index) => (
                <li key={`legend-${index}`} className="flex items-center mb-4">
                  <span className="inline-block w-4 h-4 rounded-full mr-4" style={{ backgroundColor: colors[index % colors.length] }}></span>
                  {`${entry.name}: ${entry.value}`}
                </li>
              ))}
            </ul>
            <PieChart width={350} height={300} className="w-[50%]">
              <Pie
                data={samplePieChartData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#3CB9FC"
                label={true}
              >
                {samplePieChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>
        </div>
        {/* <div className="bg-white p-4">
          <h2 className="text-lg font-semibold mb-4 text-[#B537F2]">Radar Chart</h2>
          <RadarChart cx={200} cy={150} outerRadius={100} width={400} height={300} data={sampleRadarChartData}>
            <PolarGrid />
            <PolarAngleAxis dataKey="subject" />
            <PolarRadiusAxis />
            <Tooltip />
            <Radar name="Mike" dataKey="A" stroke="#8A2BE2" fill="#8A2BE2" fillOpacity={0.6} />
            <Radar name="Lily" dataKey="B" stroke="#120052" fill="#120052" fillOpacity={0.6} />
          </RadarChart>
        </div> */}
        {/* <div className="bg-white p-4">
          <h2 className="text-lg font-semibold mb-4 text-[#B537F2]">Scatter Chart</h2>
          <ScatterChart width={400} height={300}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="x" />
            <YAxis dataKey="y" />
            <Tooltip cursor={{ strokeDasharray: '3 3' }} />
            <Legend />
            <Scatter name="A school" data={sampleScatterChartData} fill="#3CB9FC" />
          </ScatterChart>
        </div> */}
      </div>
    </AntPageContainer>
  );
};
