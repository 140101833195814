import {
  ProFormGroup,
  ProFormSegmented,
  ProFormSwitch,
} from "@ant-design/pro-components";
import { userRoleLabels } from "../../enum-labels";
import { useParams } from "react-router-dom";

export const AdministrativeFields = () => {
  const { id } = useParams();
  
  return (
    <ProFormGroup title="Administrative">
      <ProFormSegmented
        label="Role"
        name="role"
        valueEnum={userRoleLabels}
        {...(!id && { initialValue: "user" })}
        rules={[{ required: true, message: "required" }]}
      />
      
      <ProFormSwitch
        checkedChildren="Yes"
        unCheckedChildren="No"
        name="freeze"
        label="Freeze Account?"
      />
    </ProFormGroup>
  );
};
