import { ProFormGroup, ProFormText } from "@ant-design/pro-components";
import { useParams } from "react-router-dom";

export const GeneralFields = () => {
  const { id } = useParams();

  return (
    <ProFormGroup title="General">
      <ProFormText
        colProps={{ xs: 24, md: 8 }}
        name="firstName"
        label="First name"
        rules={[{ required: true, message: "required" }]}
      />

      <ProFormText
        colProps={{ xs: 24, md: 8 }}
        name="lastName"
        label="Last name"
        rules={[{ required: true, message: "required" }]}
      />

      <ProFormText
        colProps={{ xs: 24, md: 8 }}
        name="email"
        label="Email"
        rules={[{ required: true, message: "required" }]}
      />

      <ProFormText
        colProps={{ xs: 24, md: 8 }}
        name="username"
        label="Username"
        rules={[{ required: true, message: "required" }]}
      />

      <ProFormText.Password
        colProps={{ xs: 24, md: 8 }}
        name="password"
        label="Password"
        {...(!id && { rules: [{ required: true, message: "required" }] })}
      />
    </ProFormGroup>
  );
};
