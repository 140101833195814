import {
  AppstoreOutlined,
  DashboardOutlined,
  FireOutlined,
  ProfileOutlined,
  SkinOutlined,
  TrophyOutlined,
  UserOutlined,
  CreditCardOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export const useAdminMenu = () => {
  const { t } = useTranslation();
  // you can filter items by current user role etc.

  const menu = {
    path: '/admin',
    routes: [
      {
        path: '/admin/dashboard',
        icon: <DashboardOutlined />,
        key: 'dashboard',
        name: t('dashboard'),
      },

      {
        path: '/admin/users',
        icon: <UserOutlined />,
        key: 'users',
        name: 'Users',
      },
      {
        path: '/admin/transactions',
        icon: <AppstoreOutlined />,
        key: 'transactions',
        name: t('Transactions'),
        routes: [
          {
            path: './',
            hideInMenu: true,
            name: t('list_transactions'),
          },
          // {
          //   path: "create",
          //   hideInMenu: true,
          //   name: t("create_category"),
          // },
          // {
          //   path: "edit/:id",
          //   hideInMenu: true,
          //   name: t("update_category"),
          // },
        ],
      },
      {
        path: '/admin/withdrawals',
        icon: <CreditCardOutlined />,
        key: 'withdrawals',
        name: 'Withdrawals',
      },
      // {
      //   path: "/admin/skillLevels",
      //   icon: <FireOutlined />,
      //   key: "skillLevels",
      //   name: t("skill_levels"),
      //   routes: [
      //     {
      //       path: "./",
      //       hideInMenu: true,
      //       name: t("list_skill_levels"),
      //     },
      //     {
      //       path: "create",
      //       hideInMenu: true,
      //       name: t("create_skill_level"),
      //     },
      //     {
      //       path: "edit/:id",
      //       hideInMenu: true,
      //       name: t("update_skill_level"),
      //     },
      //   ],
      // },
      // {
      //   path: "/admin/exercises",
      //   icon: <FireOutlined />,
      //   key: "exercises",
      //   name: t("exercises"),
      //   routes: [
      //     {
      //       path: "./",
      //       hideInMenu: true,
      //       name: t("list_exercises"),
      //     },
      //     {
      //       path: "create",
      //       hideInMenu: true,
      //       name: t("create_exercise"),
      //     },
      //     {
      //       path: "edit/:id",
      //       hideInMenu: true,
      //       name: t("update_exercise"),
      //     },
      //   ],
      // },
      // {
      //   path: "/admin/exerciseTypes",
      //   icon: <SkinOutlined />,
      //   key: "exerciseTypes",
      //   name: t("exerciseTypes"),
      //   routes: [
      //     {
      //       path: "./",
      //       hideInMenu: true,
      //       name: t("list_exerciseTypes"),
      //     },
      //     {
      //       path: "create",
      //       hideInMenu: true,
      //       name: t("create_exerciseType"),
      //     },
      //     {
      //       path: "edit/:id",
      //       hideInMenu: true,
      //       name: t("update_exerciseType"),
      //     },
      //   ],
      // },
      // {
      //   path: "/admin/exerciseInfo",
      //   icon: <ProfileOutlined />,
      //   key: "exerciseInfo",
      //   name: t("exerciseInfo"),
      //   routes: [
      //     {
      //       path: "./",
      //       hideInMenu: true,
      //       name: t("list_exerciseInfo"),
      //     },
      //     {
      //       path: "create",
      //       hideInMenu: true,
      //       name: t("create_exerciseInfo"),
      //     },
      //     {
      //       path: "edit/:id",
      //       hideInMenu: true,
      //       name: t("update_exerciseInfo"),
      //     },
      //   ],
      // },
      // {
      //   path: "/admin/challenges",
      //   icon: <TrophyOutlined />,
      //   key: "challenges",
      //   name: t("challenges"),
      //   routes: [
      //     {
      //       path: "./",
      //       hideInMenu: true,
      //       name: t("list_challenges"),
      //     },
      //     {
      //       path: "create",
      //       hideInMenu: true,
      //       name: t("create_challenge"),
      //     },
      //     {
      //       path: "edit/:id",
      //       hideInMenu: true,
      //       name: t("update_challenge"),
      //     },
      //   ],
      // },
      // {
      //   path: "/admin/userPlans",
      //   icon: <TrophyOutlined />,
      //   key: "userPlans",
      //   name: t("user_plans"),
      //   routes: [
      //     {
      //       path: "./",
      //       hideInMenu: true,
      //       name: t("list_user_plans"),
      //     },
      //     {
      //       path: "create",
      //       hideInMenu: true,
      //       name: t("create_user_plan"),
      //     },
      //     {
      //       path: "edit/:id",
      //       hideInMenu: true,
      //       name: t("update_user_plan"),
      //     },
      //   ],
      // },
    ],
  };

  return menu;
};
