import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { message } from "antd";
import useAlert from "./shared/hooks/useAlert";

function App() {
  const [messageApi, contextHolder] = message.useMessage();
  const { text, type } = useAlert();
  if (text && type) {
    switch(type) {
      case "error": messageApi.error(text);break;
      case "success": messageApi.success(text);break;
      default: messageApi.info(text);
    }
  }
  return (
    <div>
      {contextHolder}
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
