import { ProFormDigit, ProFormGroup, ProFormMoney } from "@ant-design/pro-components";
import { FormInputField, FormInputFieldType } from "../../../shared/components/form-inputs/FormInputField";
import { formatFieldOptions } from "../../../shared/utils/common";
import { BaseFormFieldsProps } from "../../../shared/types";
import { useTranslation } from "react-i18next";
import { Transaction } from "../../types";

export interface TransactionFormFieldsProps extends BaseFormFieldsProps {
  object?: Transaction;
}

export const TransactionFormFields = (props: TransactionFormFieldsProps) => {
  const { i18n } = useTranslation();

  const fields: FormInputFieldType["options"][] = [
    {
      type: "number",
      name: "transactionId",
      label: "Transaction Number",
      // rules: [{}],
      rest: { readonly: true }
    },
    {
      type: "text",
      name: "valueDisplay",
      label: "Transaction Value",
      // rules: [{}],
      rest: { readonly: true, }
    },
    // {
    //   type: "money",
    //   name: "paidValue",
    //   label: "Paid Value",
    //   // rules: [{}],
    //   rest: { colProps: { md: 3 }, customSymbol: props.object?.currency, }
    // },

  ]


  return (
    <>
      <ProFormGroup title="General" collapsible>
        {fields.map((field) => <FormInputField key={field.name} options={field} form={props?.form} />)}
      </ProFormGroup>
    </>
  );
};
