import { Modal } from "antd";
import { FC, useState } from "react";
import { ApiResponse } from "../../types";
import { LegacyButtonType } from "antd/es/button/button";
import useAlert from "../../hooks/useAlert";
import { AxiosError } from "axios";

export interface TableSelectedItemsActionModalProps {
  // API function that handle multiple ids only
  requestFunc: (ids: number[]) => Promise<ApiResponse<any>>;
  itemIds: number[];
  isOpen?: boolean;
  title?: string;
  description?: string;
  okText?: string;
  cancelText?: string;
  okType?: LegacyButtonType;
  afterClose?: () => void;
  onCancel?: () => void;
  onSuccessCb?: () => void;
  onErrorCb?: () => void;
}

export const TableSelectedItemsActionModal: FC<
  TableSelectedItemsActionModalProps
> = (props) => {
  const {
    requestFunc,
    itemIds = [],
    isOpen = false,
    title = "Confirmation",
    description = "Are you sure you want to continue the action?",
    okText = "Confirm",
    cancelText = "Cancel",
    okType = "primary",
    afterClose,
    onCancel,
    onSuccessCb,
    onErrorCb,
  } = props;

  const { setAlert } = useAlert();
  const [loading, setLoading] = useState(false);

  const onConfirm = async () => {
    if (itemIds.length < 1) return;
    setLoading(true);

    try {
      const { message } = await requestFunc(itemIds as number[]);
      setLoading(false);
      if (onSuccessCb) onSuccessCb();
      setAlert(message ?? "Action successful", "success");
    } catch (err) {
      setLoading(false);

      if (onErrorCb) onErrorCb();

      const defaultErrMsg = "Something went wrong, please try again later.";

      if (err instanceof AxiosError) {
        const response = err.response?.data as ApiResponse<any>
        
        if (response) {
          const { message } = response;
          setAlert(message ?? defaultErrMsg, "error");
          return;
        }
      }

      setAlert(defaultErrMsg, "error");
    }
  };

  const handleCancel = () => {
    if (!loading && onCancel) onCancel();
  }

  return (
    <Modal
      open={isOpen}
      title={title}
      okText={okText}
      okType={okType}
      onOk={onConfirm}
      cancelText={cancelText}
      onCancel={handleCancel}
      afterClose={afterClose}
      confirmLoading={loading}
      cancelButtonProps={{
        disabled: loading,
      }}
    >
      {description}
    </Modal>
  );
};
