import axios from '../axios-client/instance';
import { ListWithdrawalRequest, ListWithdrawalResponse } from './types';

const withdrawalsApi = {
  // async delete(id: string) {
  //   const { data } = await axios.delete<ApiResponse<void>>(
  //     `/admin/transactions/${id}`
  //   );
  //   return data;
  // },

  async index(request: ListWithdrawalRequest) {
    const { data } = await axios.get<ListWithdrawalResponse>(
      '/admin/withdrawals',
      {
        params: request,
      }
    );
    return data;
  },

  async approveWithdrawal(id: string) {
    const response = await axios.patch(`/admin/withdrawals/${id}`);
    return response;
  },
};
export default withdrawalsApi;
